import styles from "./index.module.less";
import useScreenSize from "@/lib/hooks/utils/useScreenSize";
import Image from "next/image";
const Index = () => {
  const { width } = useScreenSize();

  const worksList = [
    {
      title: "1. Submit your files",
      desc: "Sign up and add your Original Content in less than 1 minutes.",
      imgUrl: "/images/home/howUse1.webp",
    },
    {
      title: "2. Automated Piracy Detection",
      desc: "Anti-piracy tools on the lookout for your product, 24/7",
      imgUrl: "/images/home/howUse2.webp",
    },
    {
      title: "3. Automated Takedowns & Protection",
      desc: "1-3 days those pirated URLs are removed",
      imgUrl: "/images/home/howUse3.webp",
    },
  ];
  return (
    <div className={styles.container}>
      <div className={styles.header}>What you can do with Copycobra</div>
      <div className={styles.desc}>
        Stop unauthorized use ! track and remove illegal content
      </div>
      {width <= 576 ? (
        <div className={styles.mainImgPhone}>
          <Image
            src={"/images/home/whatyoucandophone.webp"}
            alt="bro"
            layout="responsive"
            width={200}
            height={200}
          />
        </div>
      ) : (
        <div className={styles.mainImg}>
          <Image
            src={"/images/home/whatyoucando.webp"}
            alt="cobra"
            layout="responsive"
            width={200}
            height={200}
          />
        </div>
      )}

      <div className={styles.header576}>How it Works</div>
      <div className={styles.howUseList}>
        {worksList.map((item, index: number) => (
          <div key={index}>
            <div>
              <Image
                src={item.imgUrl}
                alt="CopyCobra"
                // layout="responsive"
                width={160}
                height={160}
              />
            </div>
            <div>{item.title}</div>
            <div>{item.desc}</div>
          </div>
        ))}
        <img src="/images/home/howIsWorkLine.webp" alt="CopyCobra" />
      </div>
    </div>
  );
};

export default Index;
