import styles from "./index.module.less";
interface Iserve {
  title: string;
  desc: string;
  imgUrl: string;
  imageList: Array<{
    url: string;
    alt: string;
  }>;
}
const Index = () => {
  const serverItems: Iserve[] = [
    {
      title: "Online Art Sellers",
      desc: "Protect your original handmade crafts, digital artworks, and custom designs from unauthorized copying and sales.",
      imgUrl: "/images/home/serve/home_serve_1.webp",
      imageList: [
        {
          url: "/images/home/serve/home_serve_1_1.webp",
          alt: "xxx",
        },
        {
          url: "/images/home/serve/home_serve_1_2.webp",
          alt: "xxx",
        },
        {
          url: "/images/home/serve/home_serve_1_3.webp",
          alt: "xxx",
        },
      ],
    },
    {
      title: "Course Instructors",
      desc: "Ensure your educational content, including video lectures, course materials, and tutorials, remains secure from piracy and unauthorized distribution.",
      imgUrl: "/images/home/serve/home_serve_2.webp",
      imageList: [
        {
          url: "/images/home/serve/home_serve_2_1.webp",
          alt: "xxx",
        },
        {
          url: "/images/home/serve/home_serve_2_2.webp",
          alt: "xxx",
        },
        {
          url: "/images/home/serve/home_serve_2_3.webp",
          alt: "xxx",
        },
        {
          url: "/images/home/serve/home_serve_2_4.webp",
          alt: "xxx",
        },
      ],
    },
    {
      title: "Authors and Journalists",
      desc: "Protect your literary works, articles, videos, and music from infringement, allowing you to focus on creating without fear of theft.",
      imgUrl: "/images/home/serve/home_serve_3.webp",
      imageList: [
        {
          url: "/images/home/serve/home_serve_3_1.webp",
          alt: "xxx",
        },
        {
          url: "/images/home/serve/home_serve_3_2.webp",
          alt: "xxx",
        },
        {
          url: "/images/home/serve/home_serve_3_3.webp",
          alt: "xxx",
        },
      ],
    },
    {
      title: "Content Creators",
      desc: "Monitor and protect your unique content, including images, videos, and posts, ensuring your creative efforts are respected and unauthorized use is minimized.",
      imgUrl: "/images/home/serve/home_serve_4.webp",
      imageList: [
        {
          url: "/images/home/serve/home_serve_4_1.webp",
          alt: "xxx",
        },
        {
          url: "/images/home/serve/home_serve_4_2.webp",
          alt: "xxx",
        },
        {
          url: "/images/home/serve/home_serve_4_3.webp",
          alt: "xxx",
        },
        {
          url: "/images/home/serve/home_serve_4_4.webp",
          alt: "xxx",
        },
      ],
    },
  ];
  return (
    <div className={styles.container}>
      <h2>Who We Serve</h2>
      <div className={styles.desc}>
        Protect your original works from unauthorized use. Discover the types of
        creators we support
      </div>
      <div className={styles.main}>
        {serverItems.map((item, index) => (
          <div key={index} className={styles.item}>
            <div>
              <img src={item.imgUrl} alt="" />
            </div>
            <div>{item.title}</div>
            <div>
              {item.imageList.map((it, id) => (
                <div key={id}>
                  <img src={it.url} alt={it.alt} />
                </div>
              ))}
            </div>
            <div>{item.desc}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Index;
