import { useEffect, useRef } from "react";
import styles from "./index.module.less";
const Index = () => {
  const containerRef = useRef(null);
  const list = [
    {
      url: "/images/home/rowScroll/birder.webp",
    },
    {
      url: "/images/home/rowScroll/circle.webp",
    },
    {
      url: "/images/home/rowScroll/coursera.webp",
    },
    {
      url: "/images/home/rowScroll/etsy.webp",
    },
    {
      url: "/images/home/rowScroll/ins.webp",
    },
    {
      url: "/images/home/rowScroll/medium.webp",
    },
    {
      url: "/images/home/rowScroll/quora.webp",
    },
    {
      url: "/images/home/rowScroll/redbuuule.webp",
    },
    {
      url: "/images/home/rowScroll/skill.webp",
    },
    {
      url: "/images/home/rowScroll/teach.webp",
    },
    {
      url: "/images/home/rowScroll/tiktol.webp",
    },
    {
      url: "/images/home/rowScroll/udemy.webp",
    },
    {
      url: "/images/home/rowScroll/w.webp",
    },
    {
      url: "/images/home/rowScroll/x.webp",
    },
    {
      url: "/images/home/rowScroll/youtube.webp",
    },
  ];
  useEffect(() => {
    const container = containerRef.current;

    const scrollStep = 1;
    const scrollInterval = 30;

    const scroll = () => {
      container.scrollLeft += scrollStep;
      if (
        container.scrollLeft + container.clientWidth >=
        container.scrollWidth
      ) {
        container.scrollLeft = 0;
      }
    };

    const intervalId = setInterval(scroll, scrollInterval);

    // 清理定时器
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {});
  return (
    <>
      <div className={styles.header}>
        <span>Protect Original Creators Across</span>
        <span>15</span>
        <span>Platforms</span>
      </div>
      <div className={styles.main} ref={containerRef}>
        {list.map((item: any, index: number) => (
          <img src={item.url} alt="" key={index} />
        ))}
      </div>
    </>
  );
};

export default Index;
