import styles from "./index.module.less";
import { useUserProvider } from "@/context/UserProvider";
import { useRouter } from "next/router";

const Index = () => {
  const serverList = [
    {
      title: "Platform Coverage",
      desc: "Monitoring coverage across multiple platforms in Asia, Europe, Southeast Asia, and more",
      imgUrl: "/images/home/home_server_1.png",
    },
    {
      title: "24/7 Monitoring",
      desc: "Constant surveillance of your works and content",
      imgUrl: "/images/home/home_server_2.png",
    },
    {
      title: "Infringement Alerts",
      desc: "Immediate notifications of any infringements detected",
      imgUrl: "/images/home/home_server_3.png",
    },
  ];
  const { isLogin } = useUserProvider();
  const router = useRouter();
  const handleGo = () => {
    if (isLogin) {
      router.push("/dashboard");
    } else {
      router.push("/login");
    }
  };
  return (
    <div className={styles.container}>
      <h2>Our Featured Services</h2>
      <div className={styles.main}>
        {serverList.map((item, index) => (
          <div
            style={{
              backgroundImage: `url(${item.imgUrl})`,
              backgroundPosition: index > 0 ? "bottom" : "",
            }}
            key={index}
          >
            <div>{item.title}</div>
            <div>{item.desc}</div>
          </div>
        ))}
      </div>
      <div className={styles.botBtn} onClick={handleGo}>
        Get Started
      </div>
    </div>
  );
};

export default Index;
