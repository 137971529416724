import React, { useEffect, useState } from "react";
import { CommonBackContent } from "../Layouts/CommonBackContent";
import { useConfigProvider } from "@/context/ConfigProvider";
import { useUserProvider } from "@/context/UserProvider";
import { getMetadata, saveContent, getUserUseTimesApi } from "@/lib/service";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import styles from "./index.module.less";
import { Input, message } from "antd";
import ScrollText from "./scrollText/index";
import ScrollImg from "./scrollImage/index";
import ProtextOrigin from "./protectOriginal/index";
import WhatYouCanDo from "./whatCanDo/index";
import FeaturnServer from "./featureServices/index";
const OurClients = dynamic(() => import("./ourClients/index"), {
  ssr: false,
});
import WhoWeServer from "./WhoWeServer/index";
import FreQuestion from "./freQuestions/index";
import BottomBtn from "./bottomBtn/index";
import { Button } from "antd";
const HomeContent = ({ data }: { data: any }) => {
  const { isMobile } = useConfigProvider();
  const { isLogin } = useUserProvider();
  const router = useRouter();
  const items = [
    "Online Art Sellers",
    "Course Creators",
    "Authors and Journalists",
    "Content Creators",
  ];
  const imgItems = [
    "/images/home/home_image_1.webp",
    "/images/home/home_image_2.webp",
    "/images/home/home_image_3.webp",
    "/images/home/home_image_4.webp",
  ];
  const [url, setUrl] = useState("");
  const [contentLoading, setcontentLoading] = useState(false);

  const handleSeach = async () => {
    if (isLogin) {
      if (!url) {
        message.warning("please enter a URL");
        return;
      }
      const userPoint = await getUserUseTimesApi();
      const { content_status } = userPoint.data;
      if (content_status != 0) {
        setcontentLoading(true);
        getMetadata({ url }).then((result) => {
          const add_content_param = {
            title: result.data.title,
            description: result.data.description,
            url: result.data.url,
            thumbnails: result.data.thumbnails,
            status: 1,
          };
          saveContent(add_content_param).then((res) => {
            if (res.code == 0) {
              setcontentLoading(false);
              router.push("/dashboard");
              message.success("add success");
              setUrl("");
            } else {
              setcontentLoading(false);
              message.warning(res.message);
            }
          });
        });
      } else {
        message.warning("Please purchase services");
        router.push(`/plan?url=${url}`);
      }
    } else {
      message.warning("Please log in");
      router.push(`/login?url=${url}`);
    }
  };
  return (
    <div>
      <CommonBackContent>
        <div className={styles.topMianLogo}>
          <div className={styles.left}>
            <h1>Anti-piracy Services For -</h1>
            {/* <h1>Online Art Sellers</h1> */}
            <ScrollText items={items}></ScrollText>
            <div className={styles.ipt}>
              <Input
                className={styles.inputItem}
                placeholder="Please enter a URL to quickly add content"
                value={url}
                onChange={(e) => {
                  setUrl(e.target.value);
                }}
                // type="email"
              />
              <Button
                className={styles.btn}
                onClick={handleSeach}
                loading={contentLoading}
              >
                START
              </Button>
            </div>
          </div>
          {!isMobile && (
            <div className={styles.right}>
              <ScrollImg items={imgItems}></ScrollImg>
            </div>
          )}
        </div>
      </CommonBackContent>
      <ProtextOrigin></ProtextOrigin>
      <CommonBackContent>
        <WhatYouCanDo></WhatYouCanDo>
        <FeaturnServer></FeaturnServer>
        <OurClients></OurClients>
      </CommonBackContent>
      <WhoWeServer></WhoWeServer>
      <CommonBackContent>
        <FreQuestion></FreQuestion>
      </CommonBackContent>
      <BottomBtn></BottomBtn>
    </div>
  );
};

export default HomeContent;
