import styles from "./index.module.less";
import { Collapse, CollapseProps, theme } from "antd";
import { CSSProperties } from "react";

const Index = () => {
  const dataList = [
    {
      label: `What types of content does your monitoring service cover?`,
      children: (
        <p>
          {`Our service covers a wide range of content including text, images, videos, and audio. We also monitor other intellectual property across various platforms.`}
        </p>
      ),
    },
    {
      label: `How does your real-time monitoring work?`,
      children: (
        <p>
          {`Our system uses advanced algorithms to continuously scan the internet 24/7 for unauthorized use of your content. Once an infringement is detected, you receive an immediate alert.`}
        </p>
      ),
    },
    {
      label: `Can your service help with filing DMCA takedown notices?`,
      children: (
        <p>
          {`Yes, our service includes support for generating and submitting DMCA takedown notices. We offer comprehensive takedown services to ensure your notices are effective.`}
        </p>
      ),
    },
    {
      label: `How does your service ensure the privacy and security of my data?`,
      children: (
        <p>
          {`We use industry-standard encryption and security protocols to protect your data. Our privacy policy ensures that your information is handled with the utmost care and confidentiality.`}
        </p>
      ),
    },
    {
      label: `What is the process for setting up the monitoring service?`,
      children: (
        <p>
          {`Setting up our monitoring service is straightforward. You need to provide us with the details of the content you want to monitor. Our team will then configure the system to start the monitoring process immediately.`}
        </p>
      ),
    },
    {
      label: `What if I run out of removal requests in my plan? `,
      children: (
        <p>
          {`Don't worry, you can purchase additional hosting removal packages. If you're an agency and want a custom plan, then send us an email to copycobrafast@gmail.com`}
        </p>
      ),
    },
    {
      label: `How frequently do I receive reports on monitored content?`,
      children: (
        <p>
          {`We will send you reports weekly. Each report will provide a comprehensive overview of detected infringements and the actions taken.`}
        </p>
      ),
    },
    {
      label: `How do I cancel my subscription?`,
      children: (
        <p>
          {`You're free to cancel your subscription at any time. Simply go to your Dashboard, navigate to Settings, and select 'Cancel Subscription' to proceed with the cancellation.`}
        </p>
      ),
    },
  ];
  const { token } = theme.useToken();

  const panelStyle: React.CSSProperties = {
    //   marginBottom: 24,
    //   background: "#0B0B0B",
    //   border: "1px solid rgba(68,68,68,0.87);",
    //     color: "rgba(255,255,255,0.6)",

    marginBottom: 24,
    background: `var(--ground-rgb-1)`,
    borderRadius: token.borderRadiusLG,
    border: "none",
    color: "red",
    boxShadow: `0 0 2px #D9D9D9`,
  };

  const getItems: (panelStyle: CSSProperties) => CollapseProps["items"] = (
    panelStyle,
  ) => {
    return dataList.map((item, index) => {
      return {
        ...item,
        key: `${index}`,
        style: panelStyle,
      };
    });
  };
  return (
    <div className={styles.container}>
      <h2>Frequently asked questions</h2>
      <div className={styles.main}>
        <Collapse
          className={styles.collapseContent}
          bordered={false}
          expandIconPosition="end"
          items={getItems(panelStyle)}
          defaultActiveKey={undefined}
        />
      </div>
    </div>
  );
};
export default Index;
