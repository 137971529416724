// ScrollingText.js
import React, { useRef, useEffect, useState } from "react";
import styles from "./index.module.less";

const ScrollingText: React.FC<any> = ({ items }) => {
  return (
    <div className={styles["scroll-container"]}>
      <div className={styles["scroll-content"]}>
        {items.map((item: string, index: number) => (
          <div className={styles["scroll-item"]} key={index}>
            {item}
          </div>
        ))}
        {items.map((item: string, index: number) => (
          <div className={styles["scroll-item"]} key={index + "c"}>
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScrollingText;
