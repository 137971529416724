import styles from "./index.module.less";
import { useUserProvider } from "@/context/UserProvider";
import { useRouter } from "next/router";
const Index = () => {
  const { isLogin } = useUserProvider();
  const router = useRouter();
  const handleGo = () => {
    if (isLogin) {
      router.push("/dashboard");
    } else {
      router.push("/login");
    }
  };
  return (
    <div className={styles.container}>
      <div>
        Creators like you have already signed up with CopyCobra. Join us and
        protect your originals today!
      </div>
      <div onClick={handleGo} style={{ cursor: "pointer" }}>
        Sign Up Now
      </div>
    </div>
  );
};
export default Index;
