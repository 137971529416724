// ScrollingText.js
import React, { useRef, useEffect, useState } from "react";
import styles from "./index.module.less";
import Image from "next/image";

const ScrollingText: React.FC<any> = ({ items }) => {
  return (
    <div className={styles["scroll-container"]}>
      <div className={styles["scroll-content"]}>
        {items.map((item: string, index: number) => (
          <div className={styles["scroll-item"]} key={index}>
            <img src={item} alt="coobra" />
          </div>
        ))}
        {/* 复制项目以实现无缝滚动 */}
        {items.map((item: string, index: number) => (
          <div className={styles["scroll-item"]} key={`repeat-${index}`}>
            <img src={item} alt="cobra" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScrollingText;
